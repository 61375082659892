import React, {useState} from "react";
import {Modal} from 'bootstrap';

const LoginModal = ({setToken}) => {
  const [name, setName] = useState('');
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState();

  const submit = e => {
    e.preventDefault();
    fetch("/api/auth/login", { 
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: `grant_type=password&username=${name}&password=${pwd}`
    }).then(r => {
      if(r.ok) {
        r.json()
          .then(({access_token: token}) => {
            setToken(token);
            setPwd('');
            setError();
            Modal.getInstance(document.querySelector('#loginModal')).hide();
          })
          .catch(err => setError('Login failed') );
      } else {
        setError('Login failed.');
      }
    }).catch(err => setError('Login failed'));
  };

  return (
    <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="loginModalLabel">Login</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form id="loginForm" onSubmit={submit}>
              <div className="mb-3">
                <label htmlFor="nameInput" className="form-label">Username</label>
                <input type="text" className="form-control" id="nameInput" value={name} onChange={({target: {value: name}}) => setName(name)} required/>
              </div>
              <div className="mb-3">
                <label htmlFor="pwdInput" className="form-label">Password</label>
                <input type="password" className="form-control" id="pwdInput" value={pwd} onChange={({target: {value: pwd}}) => setPwd(pwd)}required/>
              </div>
            </form>
            { error && <div className="text-danger"> {error} </div> }
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" className="btn btn-primary" form="loginForm">Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
