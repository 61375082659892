import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import {LoginContext} from './LoginContext';
import LoginModal from './LoginModal';
import { Route, Routes } from "react-router-dom";
import { UserList, EditUser, CreateUser, Profile} from "./Users";
import { WallList, EditWall } from "./Walls";
import Footer from "./Footer";
import Home from "./Home";

const Nav= () => {
  const {user, isAdmin, setToken} = useContext(LoginContext);

  return (
    <div className='container d-flex vh-100 flex-column'>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-3">
        <NavLink className="navbar-brand" to="/">Blinkengriffen</NavLink>
        { isAdmin &&
        <div className="navbar-nav">
          <NavLink className="nav-item nav-link" to="/users">Users</NavLink>
          <NavLink className="nav-item nav-link" to="/walls">Walls</NavLink>
        </div>
        }
        <LoginModal setToken={setToken}/>
        <div className="navbar-nav ms-auto align-items-center">
          { user &&
          <NavLink className="nav-item nav-link" to="/profile">
            <i className="bi bi-gear text-white fs-4 mx-2"/>
          </NavLink>
          }
          { user ?
            <a href="/#" className="nav-link active" onClick={() => setToken()}>Logout</a> :
            <a href="/#" className="nav-link active" data-bs-toggle="modal" data-bs-target="#loginModal">Login</a>
          }
        </div>
      </nav>
      <div className='flex-fill'>
      <Routes>
        <Route path="/" element={<Home />} />
        { user &&
        <Route path="/profile" element={<Profile />} />
        }
        { isAdmin &&
          <>
            <Route path="/users" element={<UserList />} />
            <Route path="/users/:id" element={<EditUser />} />
            <Route path="/users/new" element={<CreateUser />} />
            <Route path="/walls" element={<WallList />} />
            <Route path="/walls/:id" element={<EditWall />} />
          </>
        }
      </Routes>
      </div>
      <Footer/>
    </div>
  )
};

export default Nav;
