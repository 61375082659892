import React from "react";
import Nav from './Nav';
import {LoginProvider} from './LoginContext';

const App = () => {
  return (
    <LoginProvider>
      <Nav/>
    </LoginProvider>
  );
};
export default App;
