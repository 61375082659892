import React, {useMemo, createContext, useState, useEffect} from 'react';

export const LoginContext = createContext();

export const LoginProvider = ({children}) => {

  const [token, setToken] = useState(window.sessionStorage.getItem('token'));
  const [user, setUser] = useState();
  const authHeader = useMemo(() => token ? {Authorization : `Bearer ${token}`} : {}, [token]);
  const isAdmin = useMemo(() => user && user.roles.includes('admin'), [user]);

  useEffect(() => {
    window.sessionStorage.setItem('token', token);
  }, [token]);

  useEffect(() => {
    if(!token) { setUser(null); }
    else {
      fetch(`/api/users/me`, { headers:  authHeader}
      ).then(
        r => r.ok && r.json()
        .then(setUser))
        .catch(() => setUser(null));
    }
  },[token, authHeader]);

   return (
     <LoginContext.Provider value={{user: user, isAdmin: isAdmin, authHeader: authHeader, setToken: setToken}} children={children}/>
    );
};

