import React, {useRef, useContext, useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import ErrorToast from './ErrorToast';
import {LoginContext} from './LoginContext';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState();
  const {authHeader} = useContext(LoginContext);

  useEffect(() => {
    fetch(`/api/users/`, {headers: authHeader}).then(
      r => {
        if (!r.ok) {
          setError(`An error occurred: ${r.statusText}`);
          return;
        }
        r.json()
          .then(setUsers)
          .catch(setError);
      });
  }, [authHeader]);

  const remove = ({id, name}) => {
    window.confirm(`Delete user "${name}"?`) &&
      fetch(`/api/users/${id}`, {
        method: "delete",
        headers: authHeader
      }).then(r => r.ok ? 
        setUsers(users.filter(user => user.id != id)) :
          setError(r.statusText)
      )
      .catch(setError);
  };

  return (
    <div className='container'>
      <h1>Users</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>ID</th>
            <th>Roles</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {
            users.map(user => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>{user.id}</td>
                <td>{user.roles}</td>
                <td>
                  <Link to={`/users/${user.id}`}>
                    <button className='btn btn-primary mx-1'>Edit</button>
                  </Link>
                  <button className='btn btn-danger mx-1' onClick={() => remove(user)}>Delete</button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <Link to={`/users/new`}>
        Add user
      </Link>
      <ErrorToast error={error}/>
    </div>
  );
};

const UserForm = ({onSubmit, user, setUser, notAdmin}) => {
  const toggleRole = role => setUser({...user,  roles:
    user.roles.includes(role) ?
    user.roles.filter(r => r !== role) :
    user.roles.concat([role])
  } );

  const [repeatPwd, setRepeatPwd] = useState();
  const repeatPwdRef =useRef();

  useEffect(() => {
     repeatPwdRef.current.setCustomValidity(user.pwd && user.pwd !== repeatPwd ?
       'Passwords do not match.' :
       ''
     );
  }, [user.pwd, repeatPwd]);

  const submit = e => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form onSubmit={submit}>
      <div className="mb-3">
        <label className="form-label" htmlFor="inputName">Name</label>
        <input 
          id="inputName" 
          className="form-control" 
          value = {user.name}
          onChange={({target: {value: name}}) => setUser({...user, name: name})}
          placeholder="User name"
          disabled={notAdmin}
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="inputPwd">Password</label>
        <input 
          id="inputPwd" 
          type="password"
          className="form-control" 
          value = {user.pwd}
          onChange={({target: {value: pwd}}) => setUser({...user, pwd: pwd})}
          placeholder="Password"
        />
      </div>
      <div className="mb-3">
        <label className="form-label" htmlFor="inputPwd">Repeat Password</label>
        <input 
          ref = {repeatPwdRef}
          id="repeatPwd" 
          type="password"
          className="form-control" 
          value = {repeatPwd}
          onChange={({target: {value: pwd}}) => setRepeatPwd(pwd)}
          placeholder="Password"
        />
      </div>
      <div className="pb-3">
        <h3>Roles</h3>
        {
          ['admin', 'wall creator'].map(role => (
            <div key={role} className="form-check form-switch my-3">
              <input type="checkbox" className="form-check-input" id={`checkbox_${role}`} checked = {user.roles.includes(role)} onChange = {() => toggleRole(role)} autoComplete="off" disabled={notAdmin}/>
              <label className="form-check-label" htmlFor={`checkbox_${role}`}>{role}</label>
            </div>
          ))
        }
      </div>
      <button type="submit" className="btn btn-primary">Save</button>
    </form>
  );
};

const EditUser = () => {
  const {id} = useParams();
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const {authHeader} = useContext(LoginContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`/api/users/${id}`, {headers: authHeader})
      .then(r => r.json()
        .then(body => { 
          if(!r.ok) { throw(new Error(`An error occurred: ${r.statusText} ${body.detail}`)); }
          setUser(body);
        })
      ).catch(setError);
  }, [id, authHeader]);

  const save = () => {
    fetch(`/api/users/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...authHeader
      },
      body: JSON.stringify(user),
    })
      .then(r => r.json()
        .then(body => { 
          if(!r.ok) { throw(new Error(`An error occurred: ${r.statusText} ${body.detail}`)); }
          navigate('/users');
        })
      ).catch(setError);
  };

  return (
    <div className='container'>
      <h1>Edit User</h1>
      { user && <UserForm onSubmit={save} user={user} setUser={setUser}/> }
      <ErrorToast error = {error}/>
    </div>
  );
};

const CreateUser = () => {
  const [user, setUser] = useState({name: "", pwd:"", roles:[]});
  const [error, setError] = useState();
  const {authHeader} = useContext(LoginContext);
  const navigate = useNavigate();

  const save = () => {
    fetch("/api/users/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...authHeader
      },
      body: JSON.stringify(user),
    })
      .then(r => r.json()
        .then(body => { 
          if(!r.ok) { throw(new Error(`An error occurred: ${r.statusText} ${body.detail}`)); }
          navigate('/users');
        })
      ).catch(setError);
  };

  return (
    <div className='container'>
      <h1>Create User</h1>
      { user && <UserForm onSubmit={save} user={user} setUser={setUser}/> }
      <ErrorToast error = {error}/>
    </div>
  );
};

const Profile = () => {
  const {user: me, authHeader} = useContext(LoginContext);
  const [user, setUser] = useState(me);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const save = () => {
    fetch(`/api/users/me`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        ...authHeader
      },
      body: JSON.stringify(user),
    })
      .then(r => r.json()
        .then(body => { 
          if(!r.ok) { throw(new Error(`An error occurred: ${r.statusText} ${body.detail}`)); }
          navigate('/');
        })
      ).catch(setError);
  };

  return (
    <div className='container'>
      <h1>Profile</h1>
      { user && <UserForm notAdmin={true} onSubmit={save} user={user} setUser={setUser}/> }
      <ErrorToast error = {error}/>
    </div>
  );
};

export { Profile,UserList, EditUser, CreateUser};
