import React, {useRef, useEffect} from 'react';
import {Toast} from 'bootstrap';

const style = {
  position: 'fixed',
  left: '50%',
  transform: 'translate(-50%, 0px)',
};

const ErrorToast = ({error}) => {
  const toastRef=useRef();

  useEffect(() => {
    console.log(error);
    error && new Toast(toastRef.current).show();
  }, [error]);
  return (
    <div className="toast" style={style} role="alert" ref={toastRef}>
      <div className="toast-header">
        <strong className='me-auto'>Error</strong>
        <button type="button" className="btn-close" data-bs-dismiss="toast"></button>
      </div>
      <div className="toast-body">
        {error && error.toString()}
      </div>
    </div>
  );
};
export default ErrorToast;
