import React from "react";

const changelog = [
  {
    date: new Date('2024-11-10'),
    version: '2.0.0',
    changes: [
      'Major rework.',
      'Automatic offline-first boulder synchonization',
      'Tags stored per user and synchronized',
      'Introducing Session list',
      'Kiosk mode reworked, introcing pinned boulders',
      'Reworked LED update'
    ]
  },
  {
    date: new Date('2023-12-04'),
    version: '1.2.0',
    changes: [
      'Introducing LED lock button: LEDs will keep showing the same boulder until unlocked.',
      'Various bugfixes'
    ]
  },
  {
    date: new Date('2023-08-29'),
    version: '1.1.0',
    changes: [
      'Proper boulder ordering in Boulder view',
      'Disabled modification of not owned boulders'
    ]
  },
  {
    date: new Date('2023-07-28'),
    version: '1.0.0',
    changes: [
      'Production! :)'
    ]
  },
  {
    date: new Date('2023-07-20'),
    version: '0.1.3',
    changes: [
      'Enabling uploading walls + boulders',
      'Introducing kiosk mode',
      'Introducing LED Test'
    ]
  },
  {
    date: new Date('2023-04-07'),
    version: '0.1.2',
    changes: [
      'Introducing Boulder Tags.',
      'Fixed some performance issues.'
    ]
  },
  {
    date: new Date('2022-11-07'),
    version: '0.1.1',
    changes: [
      'BREAKING: Modified bluetooth protocol, app update requires controller update.',
      'Maximum holds/boulder is now 102.',
      'Boulder editor is fast again.'
    ]
  },
  {
    date: new Date('2022-10-17'),
    version: '0.1.0',
    changes: [
      'Introducing app version and changelog.',
      'Fixed grade selection slider.',
      'Added hold type "foot".',
      'Bluetooth reconnect has now 6 seconds delay to avoid Android scan throttle.',
      'Boulder and wall editor now have a "save" option instead of instantly persisting changes.',
      'New option to mirror boulders on symmetrical walls.'
    ]
  }
];



const Home = () => {
  return (
    <div>
      <div className='text-center py-5'>
        <h1>Blinkengriffen</h1>
        <p className='lead'>Here's an app for your spray wall: take a picture, mark all the holds, save your boulders. 
          <br/>
          Ask me for an account if you wanna upload and share your wall/boulders. Also there's an LED system...</p>
        <a href='/static/blinkengriffen.apk'>
          <button type="button" className="btn btn-light fs-3">Download App (Android)</button>
        </a>
      </div>
      <hr/>
      <div className='mt-5'>
        <h3>Changelog</h3>
        { changelog.map(i => (
          <div>
            <h5>{i.version}</h5><h6>{i.date.toDateString()}</h6>
            <ul>
              {i.changes.map(c => ( 
                <li>{c}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      {/**
      <div className='mt-5'>
        <h3>Outlook</h3>
        Planned features:
        <ul>
          <li><b>Locking bluetooth</b>, such that you can keep a boulder on the wall while browsing away.</li>
        </ul>
      </div>
        **/
      <hr/>
      }
      <div className='text-center py-5'>
        <a href='/static/beta.apk'>
          <button type="button" className="btn btn-light fs-3">Download Beta Version (install at own risk!)</button>
        </a>
      </div>
    </div>
  )
};

export default Home;
