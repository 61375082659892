import React from 'react';

const email = e => {
  window.location ='maxilto:cxoxdexr@poxstexo@@@de'.replace('@@@','.').replaceAll('x','');
  e.preventDefault();
};
  
const Footer = () => (
  <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-3">
    <div className="navbar-nav ms-auto align-items-center">
      <a href="" className="nav-link active" onClick={email}>Contact</a>
    </div>
  </nav>
);

export default Footer;
